<!--编辑meeting-->
<template>
  <div class="editMeeting" style="width: 100%">
    <div class="editMeeting-container">
      <v-row class="ma-0 pa-0">
        <v-col cols="12" md="12" class="ma-0 pa-0 text-style text">
          <div class="title x-title">
            <img
              src="@/assets/images/map/meet_calendar_room@2x.png"
              style="float: left; width: 25px; margin: 8px 5px"
            />
            Book Your Space
            <i
              class="iconfont icon-chahao"
              style="float: right; margin-right: 20px; cursor: pointer"
              @click="back"
            ></i>
          </div>
        </v-col>
      </v-row>
      <div class="content">
        <img
          v-show="data.rooms[0]"
          :src="data.rooms[0] ? data.rooms[0].roomImage : ''"
          style="width: 100%; height: 230px; margin: 0px !important"
          alt=""
          obj-fit="cover"
        />
        <!--<v-img :src="data.rooms[0].roomImage" style="width:100%;height:230px" alt="" obj-fit="cover">-->
        <!--</v-img>-->
        <!--日期显示-->
        <div class="content-date x-background">
          <v-btn
            class="timeBtn"
            rounded
            fill
            color="#fff"
            tabindex="3"
            @click="changeTime(startBtn, true)"
            @keyup.enter.native="changeTime(startBtn, true)"
          >
            <span class="timeBtn" style="color: #000">{{ startBtn }}</span>
          </v-btn>
          <v-btn
            rounded
            fill
            color="#fff"
            tabindex="4"
            @click="changeTime(endBtn, false)"
            @keyup.enter.native="changeTime(endBtn, false)"
          >
            <span style="color: #000">{{ endBtn }}</span>
          </v-btn>
          <v-btn
            rounded
            fill
            class="x-btn"
            @click="updateInfo"
            @keyup.enter="updateInfo"
            tabindex="5"
            >Update
          </v-btn>
        </div>

        <!--summary 和 description-->
        <div class="content-bottom">
          <v-text-field
            label="Summary"
            v-model="summary"
            style="width: 90%; margin: auto"
          ></v-text-field>
          <v-container fluid v-show="client != 'NatWest'">
            <v-textarea
              label="Description"
              v-model="description"
              value="This is clearable text."
            ></v-textarea>
          </v-container>
          <!--<v-btn rounded-->
          <!--       v-show="client!='NatWest'"-->
          <!--       outlined-->
          <!--       style="display: block;margin:20px auto"-->
          <!--       :class="{'x-btn-active': addGoogle==true}"-->
          <!--       @click="clickAddGoogle"-->
          <!--&gt;-->
          <!--  Add Microsoft Teams-->
          <!--</v-btn>-->
          <!--<v-btn  v-if="client!='NatWest'" rounded outlined @click="jumpToSelectPa" style="display: block;margin: auto">-->
          <!--  Select Participants-->
          <!--</v-btn>-->
          <div
            class="btns"
            style="text-align: center"
            v-show="client != 'NatWest'"
          >
            <!-- 如果type是parameter而且isOnlineMeeting为true则高亮-->
            <!-- 如果type是api则不存在高亮，就发个api就完了-->
            <v-btn
              style="display: block; margin: auto; margin-bottom: 20px"
              v-for="(item, index) in onlineMeeting"
              :key="'onlineMeeting' + index"
              v-show="client != 'NatWest'"
              rounded
              outlined
              :class="{
                'x-btn-active':
                  item.type == 'parameter' && data.isOnlineMeeting,
              }"
              @click="clickAddGoogle(item, index)"
            >
              {{ item.label }}
            </v-btn>
            <!--            <v-btn-->
            <!--              style="display: block;margin: auto;margin-bottom: 20px"-->
            <!--              v-for="(item,index) in onlineMeeting"-->
            <!--              v-show="client!='NatWest'"-->
            <!--              rounded-->
            <!--              outlined-->
            <!--              :class="{'x-btn-active': item.checked}"-->
            <!--              @click="clickAddGoogle(item,index)"-->
            <!--            >-->
            <!--              {{ item.label }}-->
            <!--            </v-btn>-->
          </div>
          <div style="text-align: center" v-show="client != 'NatWest'">
            <v-btn rounded outlined @click="jumpToSelectPa">
              Select Participants
            </v-btn>
          </div>

          <!--展示与会人员-->
          <staff
            v-if="client != 'NatWest'"
            v-for="(item, index) in attendees"
            :key="'attendees' + index"
            :ref="'staff' + index"
            :data="item"
            style="cursor: pointer"
            class="staff"
            :showCancel="true"
            v-on:cancel="cancel"
          ></staff>
        </div>
      </div>
    </div>
    <!--日期选择-->
    <v-dialog pa="5" v-model="dialogDate" persistent max-width="600px">
      <v-card style="height: 400px">
        <v-card-title>
          <span class="headline">{{ datePickerTitle }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <!--Date-->
              <v-col cols="12" @keyup.enter="menuDialog = true">
                <v-menu
                  v-model="menuDialog"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="dateDialog"
                      label="Date"
                      prepend-icon="mdi-calendar"
                      readonly
                      clearable
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="dateDialog"
                    @input="menuDialog = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12">
                <Time
                  v-model="timeDialog"
                  format="hh:mm a"
                  :minute-interval="30"
                  manual-input
                  :placeholder="timePlaceHolder"
                  :input-width="'100%'"
                ></Time>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <!--操作按钮-->
        <v-card-actions>
          <v-spacer></v-spacer>
          <span
            style="margin-right: 30px"
            class="dateBtn"
            tabindex="1"
            @click="dialogDate = false"
            @keyup.enter="dialogDate = false"
          >
            Close
          </span>
          <span
            class="dateBtn"
            tabindex="1"
            @click="clickSave"
            @keyup.enter="clickSave"
          >
            Save
          </span>
          <!--<v-btn-->
          <!--  color="blue darken-1"-->
          <!--  text-->
          <!--  @click="dialogDate = false"-->
          <!--&gt;-->
          <!--  Close-->
          <!--</v-btn>-->
          <!--<v-btn-->
          <!--  color="blue darken-1"-->
          <!--  text-->
          <!--  @click="clickSave"-->
          <!--&gt;-->
          <!--  Save-->
          <!--</v-btn>-->
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import moment from "moment";
import staff from "@/views/dashboard/componentsHa/staff";
import Time from "vue2-timepicker";
import "vue2-timepicker/dist/VueTimepicker.css";
import showDelegate from "@/util/delegate";

export default {
  name: "editMeeting",
  props: {
    data: {
      type: Object,
    },
    isOnlineMeeting: {
      type: Boolean,
    },
    // meeting的与会人员
    // participants: {
    //   type: Array
    // },
  },
  components: {
    staff,
    Time,
  },
  data() {
    return {
      onlineMeeting: [], //Add participant（Add zoom）按钮的配置
      originalData: {}, //没有改动过的原始数据
      client: "",
      addGoogle: false,
      startBtn: "",
      endBtn: "",
      summary: "",
      description: "",
      dialogDate: false, //更改日期的Dialog
      menuDialog: false, //日期的菜单
      dateDialog: "", //日期
      timeDialog: "", //时间
      timePlaceHolder: "",
      editStart: true, //true打开的是开始时间 false为结束时间
      startYear: "", //开始日期的年份
      endYear: "", //结束日期的年份
      datePickerTitle: "Start Date",
      attendees: [], //与人人员
      rooms: [],
    };
  },
  methods: {
    // clickAddGoogle(data, index) {
    //   this.addGoogle = !this.addGoogle;
    // },
    // clickAddGoogle(data, index) {
    //   console.log("==== data=====", data)
    //   let that = this;
    //   if (data.type == 'api') {
    //     if (data.checked) {
    //       that.$set(that.onlineMeeting[index], "checked", false);
    //       let i = that.description.indexOf(that.descriptionAddText);
    //       console.log("====description =====", that.description);
    //       console.log("====descriptionAddText =====", that.descriptionAddText);
    //       console.log("====i =====", i)
    //       // console.log("====123 =====",that.description.indexOf(that.descriptionAddText));
    //       that.description = that.description.substring(0, i)
    //     } else {
    //       let title = that.summary;
    //       let email = localStorage.getItem("userEmail");
    //       let string = this.startYear + this.startBtn;
    //       let string2 = this.endYear + this.endBtn;
    //       let start = moment(string, "YYYYddd,MMM,D h:mm a").unix();
    //       let end = moment(string2, "YYYYddd,MMM,D h:mm a").unix();
    //       let duration = end - start;
    //       let meetingId = localStorage.getItem("meetingIdMap");
    //       let apiType = that.onlineMeeting.name;
    //       this.bus.$emit('loading', true);
    //       // console.log("====time =====",that.time)
    //       // console.log("====title =====",title)
    //       // console.log("====email =====",email)
    //       // console.log("====start =====",start)
    //       // console.log("====end =====",end)
    //       // console.log("====title =====",title)
    //       // console.log("====duration =====",duration)
    //       // console.log("====meetingId =====",meetingId)
    //       // console.log("====apiType =====",apiType)
    //       this.$axios({
    //         method: 'GET',
    //         url: '/m/onlinemeeting/create',
    //         data: {
    //           title: title,
    //           email: email,
    //           start: start,
    //           duration: duration,
    //           meetingId: meetingId,
    //           apiType: apiType,
    //         }
    //       }).then(res => {
    //         this.bus.$emit('loading', false);
    //         console.log('res:', res);
    //         if (res.data.result == "failed") {
    //           that.$confirm({
    //             title: "Confirmation",
    //             message: res.data.message,
    //             show: true
    //           }).then(res => {
    //             // that.addGoogle = false;
    //             that.$set(that.onlineMeeting[index], "checked", false);
    //           })
    //         } else {
    //           console.log("====res.data.data.text!!!!!!!!!!!!! =====", res.data.data.text)
    //           // that.addGoogle = true;
    //           that.$set(that.onlineMeeting[index], "checked", true);
    //           that.descriptionAddText = res.data.data.text;
    //           that.description += "\n";
    //           that.description = that.description.concat(res.data.data.text);
    //         }
    //       }).catch(err => {
    //         this.bus.$emit('loading', false);
    //         console.log(err);
    //       })
    //     }
    //   } else {
    //     this.addGoogle = !this.addGoogle;
    //     that.$set(that.onlineMeeting[index], "checked", !data.checked);
    //   }
    // },
    clickAddGoogle(data, index) {
      let that = this;
      if (data.type == "api") {
        if (data.checked) {
          // that.$set(that.onlineMeeting[index], "checked", false);
          let i = that.description.indexOf(that.descriptionAddText);
          console.log("====description =====", that.description);
          console.log("====descriptionAddText =====", that.descriptionAddText);
          console.log("====i =====", i);
          // console.log("====123 =====",that.description.indexOf(that.descriptionAddText));
          that.description = that.description.substring(0, i);
        } else {
          let title = that.summary;
          let email = localStorage.getItem("userEmail");
          let string = this.startYear + this.startBtn;
          let string2 = this.endYear + this.endBtn;
          let start = moment(string, "YYYYddd,MMM,D h:mm a").unix();
          let end = moment(string2, "YYYYddd,MMM,D h:mm a").unix();
          let duration = end - start;
          let meetingId = localStorage.getItem("meetingIdMap");
          let apiType = that.onlineMeeting.name;
          this.bus.$emit("loading", true);
          // console.log("====time =====",that.time)
          // console.log("====title =====",title)
          // console.log("====email =====",email)
          // console.log("====start =====",start)
          // console.log("====end =====",end)
          // console.log("====title =====",title)
          // console.log("====duration =====",duration)
          // console.log("====meetingId =====",meetingId)
          // console.log("====apiType =====",apiType)
          this.$axios({
            method: "GET",
            url: "/m/onlinemeeting/create",
            data: {
              title: title,
              email: email,
              start: start,
              duration: duration,
              meetingId: meetingId,
              apiType: apiType,
            },
          })
            .then((res) => {
              this.bus.$emit("loading", false);
              console.log("res:", res);
              if (res.data.result == "failed") {
                that
                  .$confirm({
                    title: "Confirmation",
                    message: res.data.message,
                    show: true,
                  })
                  .then((res) => {
                    // that.addGoogle = false;
                    // that.$set(that.onlineMeeting[index], "checked", false);
                  });
              } else {
                console.log(
                  "====res.data.data.text!!!!!!!!!!!!! =====",
                  res.data.data.text
                );
                // that.addGoogle = true;
                // that.$set(that.onlineMeeting[index], "checked", true);
                that.descriptionAddText = res.data.data.text;
                that.description += "\n";
                that.description = that.description.concat(res.data.data.text);
              }
            })
            .catch((err) => {
              this.bus.$emit("loading", false);
              console.log(err.response);
              let auth = "";
              if (err.response.data && err.response.data.auth) {
                auth = err.response.data.auth;
                auth = auth.replace("microsoft-edge-", "");
              }
              if (auth) {
                window.open(auth);
              }
            });
        }
      } else {
        this.addGoogle = !this.addGoogle;
        // that.$set(that.onlineMeeting[index], "checked", !data.checked);
        this.$emit("refreshOnlineMeeting", !this.data.isOnlineMeeting);
      }
    },

    back() {
      this.$emit("back", true);
      this.initData();
    },
    // 选参加会议的人
    jumpToSelectPa() {
      // this.$emit("jumpToSelectPa", this.attendees);
      this.$emit("jumpToSelectPa", this.attendees);
    },
    // 右侧点击叉号删除与会人员
    cancel(data) {
      console.log("data:", data);
      console.log("this.data", this.data);
      // console.log("attendees:", this.attendees);
      let length = this.attendees.length;
      for (let i = 0; i < length; i++) {
        let item = this.attendees[i];
        // 从space预定meeting时就有attendees的话，bookingList的名单上员工的属性是attendeeId，直接搜索出来的员工属性是id，有点乱这里
        if (item.hasOwnProperty("attendeeId")) {
          if (
            data.id == item.attendeeId ||
            data.attendeeId == item.attendeeId
          ) {
            this.attendees.splice(i, 1);
            break;
          }
        } else {
          if (data.id == item.id) {
            this.attendees.splice(i, 1);
            break;
          }
        }
      }
    },
    // cancel(data) {
    //   console.log("data:", data);
    //   console.log("attendees:", this.attendees);
    //   let length = this.attendees.length;
    //   for (let i = 0; i < length; i++) {
    //     let item = this.attendees[i];
    //     // 从space预定meeting时就有attendees的话，bookingList的名单上员工的属性是attendeeId，直接搜索出来的员工属性是id，有点乱这里
    //     if (item.hasOwnProperty("attendeeId")) {
    //       if (data.id == item.attendeeId || data.attendeeId == item.attendeeId) {
    //         this.attendees.splice(i, 1);
    //         break;
    //       }
    //     } else {
    //       if (data.id == item.id) {
    //         this.attendees.splice(i, 1);
    //         break;
    //       }
    //     }
    //   }
    // },
    // 保存更改时间
    clickSave() {
      let time = this.dateDialog + this.timeDialog;
      let timezone = localStorage.getItem("timezone");
      if (this.editStart) {
        if (timezone) {
          this.startYear = moment
            .tz(time, "YYYY-MM-DDh:mm a", timezone)
            .format("YYYY");
          this.startBtn = moment
            .tz(time, "YYYY-MM-DDh:mm a", timezone)
            .format("ddd,MMM,D h:mm a");
        } else {
          this.startYear = moment(time, "YYYY-MM-DDh:mm a").format("YYYY");
          this.startBtn = moment(time, "YYYY-MM-DDh:mm a").format(
            "ddd,MMM,D h:mm a"
          );
        }
        // this.startYear = moment(time, "YYYY-MM-DDh:mm a").format("YYYY");
        // this.startBtn = moment(time, "YYYY-MM-DDh:mm a").format("ddd,MMM,D h:mm a");
      } else {
        if (timezone) {
          this.endYear = moment
            .tz(time, "YYYY-MM-DDh:mm a", timezone)
            .format("YYYY");
          this.endBtn = moment
            .tz(time, "YYYY-MM-DDh:mm a", timezone)
            .format("ddd,MMM,D h:mm a");
        } else {
          this.endYear = moment(time, "YYYY-MM-DDh:mm a").format("YYYY");
          this.endBtn = moment(time, "YYYY-MM-DDh:mm a").format(
            "ddd,MMM,D h:mm a"
          );
        }
        // this.endYear = moment(time, "YYYY-MM-DDh:mm a").format("YYYY");
        // this.endBtn = moment(time, "YYYY-MM-DDh:mm a").format("ddd,MMM,D h:mm a");
      }
      this.dialogDate = false;
      console.log("time:", time);
    },
    // 打开更改时间弹窗
    changeTime(data, isStart) {
      console.log("====Tanchuang =====", data);
      let string = "";
      if (isStart) {
        this.timePlaceHolder = "Start Time";
        this.datePickerTitle = "Start Time";
        string = this.startYear + data;
      } else {
        this.timePlaceHolder = "End Time";
        this.datePickerTitle = "End Time";
        string = this.endYear + data;
      }
      // console.log("string:",string);
      // let test = "2021Sat,Mar,6 1:30 am";
      // let test2 = "2021-3-5";
      // console.log("test:",moment(test,"YYYYddd,MMM,d h:mm a").unix());
      // console.log("test2:",moment(test2,"YYYY-M-D").unix());

      this.dateDialog = moment(string, "YYYYddd,MMM,D h:mm a").format(
        "YYYY-MM-DD"
      );
      this.timeDialog = moment(string, "YYYYddd,MMM,D h:mm a").format(
        "hh:mm a"
      );
      this.editStart = isStart; //true打开的是开始时间 false为结束时间
      this.dialogDate = true;
    },
    // 初始化数据
    initData() {
      console.log("==== 初始化数据 =====", this.data);
      let timezone = localStorage.getItem("timezone");
      if (timezone) {
        this.startYear = moment(this.data.itemStartUnix, "X").format("YYYY");
        this.endYear = moment(this.data.itemEndUnix, "X").format("YYYY");
        this.startBtn = moment
          .tz(this.data.itemStartUnix, "X", timezone)
          .format("ddd,MMM,D h:mm a");
        this.endBtn = moment
          .tz(this.data.itemEndUnix, "X", timezone)
          .format("ddd,MMM,D h:mm a");
      } else {
        this.startYear = moment(this.data.startTime).format("YYYY");
        this.endYear = moment(this.data.startTime).format("YYYY");
        this.startBtn = moment(this.data.startTime).format("ddd,MMM,D h:mm a");
        this.endBtn = moment(this.data.endTime).format("ddd,MMM,D h:mm a");
      }
      // this.startYear = moment(this.data.startTime).format("YYYY");
      // this.endYear = moment(this.data.startTime).format("YYYY");
      // this.startBtn = moment(this.data.startTime).format("ddd,MMM,D h:mm a");
      // this.endBtn = moment(this.data.endTime).format("ddd,MMM,D h:mm a");
      this.summary = this.data.summary;
      this.description = this.data.description;
      this.attendees = JSON.parse(JSON.stringify(this.data.attendees)); //本地的attendees
      this.rooms = this.data.rooms;
      // console.log("attendees:",this.attendees)
    },
    // initData() {
    //   console.log("==== 初始化数据 =====",this.data)
    //   this.startYear = moment(this.data.startTime).format("YYYY");
    //   this.endYear = moment(this.data.startTime).format("YYYY");
    //   this.startBtn = moment(this.data.startTime).format("ddd,MMM,D h:mm a");
    //   this.endBtn = moment(this.data.endTime).format("ddd,MMM,D h:mm a");
    //   this.summary = this.data.summary;
    //   this.description = this.data.description;
    //   this.attendees = JSON.parse(JSON.stringify(this.data.attendees));//本地的attendees
    //   this.rooms = this.data.rooms;
    //   // console.log("attendees:",this.attendees)
    // },
    updateInfo() {
      console.log("this.addGoogle", this.addGoogle);
      console.log("onlineMeeting", this.onlineMeeting);
      let that = this;
      this.bus.$emit("loading", true);
      let timezone = localStorage.getItem("timezone");
      let string = this.startYear + this.startBtn;
      let string2 = this.endYear + this.endBtn;
      let start;
      let end;
      if (timezone) {
        start = moment.tz(string, "YYYYddd,MMM,D h:mm a", timezone).unix();
        end = moment.tz(string2, "YYYYddd,MMM,D h:mm a", timezone).unix();
      } else {
        start = moment(string, "YYYYddd,MMM,D h:mm a").unix();
        end = moment(string2, "YYYYddd,MMM,D h:mm a").unix();
      }
      // let start = moment(string, "YYYYddd,MMM,D h:mm a").unix();
      // let end = moment(string2, "YYYYddd,MMM,D h:mm a").unix();

      let room = [];
      let roomData;
      if (this.rooms[0]) {
        if (this.rooms[0].resourceId) {
          roomData = this.rooms[0].resourceId;
        } else if (this.rooms[0].resourceEmail) {
          roomData = this.rooms[0].resourceEmail;
        } else if (this.data.organizer.email) {
          roomData = this.data.organizer.email;
        }
      }
      room.push(roomData);
      let attendees = [];
      this.attendees.map((item) => {
        attendees.push(item.email);
      });
      // this.data.attendees.map(item => {
      //   attendees.push(item.email);
      // })

      this.$axios({
        method: "POST",
        url: this.$usev2
          ? "/m/v2/spaces/updateEvent/"
          : "/m/spaces/updateEvent/",
        data: {
          calendarId: localStorage.userEmail, //订房间的人的email
          rooms: room, //房间的resourceId, 数组
          meetingId: localStorage.meetingIdMap, //campus的id
          start: start, //开始时间戳
          end: end, //结束时间戳
          summary: this.summary, //标题
          description: this.description, //详情
          attendees: attendees, //参会人员的email, 数组
          eventId: this.data.eventId,
          isOnlineMeeting: this.data.isOnlineMeeting ? 1 : 0, //是否显示email phone之类得详细信息

          // isOnlineMeeting: this.addGoogle ? 1 : 0,//是否显示email phone之类得详细信息
          // token: localStorage.token
        },
      })
        .then((res) => {
          // this.bus.$emit('loading', false);
          if (res.data.result == "failed") {
            this.$confirm({
              title: "Confirmation",
              message: res.data.message,
              show: true,
            });
            this.bus.$emit("loading", false);
            return;
          } else {
            console.log("res123", res);
            // this.data.attendees = this.attendees;
            // this.$emit("refreshRoom", this.data.eventId);
            let data = {};
            data.start = start;
            data.end = end;
            data.summary = this.summary;
            data.description = this.description;

            let startDate = moment(string, "YYYYddd,MMM,D h:mm a")
              .utc()
              .format("yyyy-MM-DD");
            let startTime = moment(string, "YYYYddd,MMM,D h:mm a")
              .utc()
              .format("HH:mm:ss");
            let startDateTime = startDate + "T" + startTime + "+0000";

            let endDate = moment(string2, "YYYYddd,MMM,D h:mm a")
              .utc()
              .format("yyyy-MM-DD");
            let endTime = moment(string2, "YYYYddd,MMM,D h:mm a")
              .utc()
              .format("HH:mm:ss");
            let endDateTime = endDate + "T" + endTime + "+0000";
            data.startTime = startDateTime;
            data.endTime = endDateTime;
            data.description = res.data.data.description;
            console.log("==== data.startTime  =====", data.startTime);
            console.log("==== data.endTime  =====", data.endTime);

            this.$emit("refreshRoom", this.attendees, data); //要改变父组件的attendees，这里有个坑要注意
            // if (window.gtag) {
            //   window.gtag("event", "Booking", {
            //     event_category: "Meeting Edit Update Click",
            //     event_label: "Update",
            //     send_to: localStorage.getItem("googleAnaId") || "",
            //     dimension1: localStorage.getItem("campusName") || ""
            //   });
            // }
            this.$gtag.event('Booking', {
              event_category: 'Meeting Edit Update Click',
              event_label: 'Update',
              send_to: localStorage.getItem("googleAnaId") || "",
              dimension1: localStorage.getItem("campusName") || "",
            });
          }
        })
        .catch((err) => {
          this.bus.$emit("loading", false);
          console.log(err);
        });
    },
    //add microsoft team 逻辑
    initAddLogic() {
      let that = this;
      this.summary = localStorage.userName + "'s Meeting";
      this.description = "";
      if (JSON.parse(localStorage.config).onlineMeeting.length > 0) {
        this.onlineMeeting = JSON.parse(localStorage.config).onlineMeeting;
        // this.onlineMeeting.map((item, index) => {
        //   that.$set(that.onlineMeeting[index], "checked", false);
        //   // item.checked = false; //踩坑！！！！！！！！如果要用$set响应式修改数据，如果初始数据中没这个属性，也要用$set的方式添加，否则$set修改视图也不会刷新
        // })
      } else {
        let lable = "Add Zoom Link";
        let type = "parameter";
        if (this.client == "adobe") {
          lable = "Add Teams";
          type = "api";
        } else if (this.client == "CX Campus") {
          lable = "Add Google Meet";
        }
        this.onlineMeeting = [
          {
            name: "",
            label: lable,
            type: type,
            checked: false,
          },
        ];
      }
    },
  },
  created() {
    console.log("1111isOnlineMeeting", this.isOnlineMeeting);
    console.log("1111data", this.data);
    this.client = localStorage.getItem("client");
    this.initAddLogic();
    this.initData();
  },
};
</script>

<style scoped lang="scss">
.editMeeting {
  .editMeeting-container {
    overflow-x: hidden;

    .title {
      //background: $main-blue;
      color: #ffffff;
      height: 3rem;
      line-height: 3rem;
      text-align: center;
    }

    .content {
      //height: 780px;
      //overflow-y: auto;
      //overflow-x: hidden;
      width: calc(100% + 18px);

      .content-date {
        //background: $main-blue;
        padding: 10px;

        button {
          display: block;
          margin: 20px auto;

          &:focus {
            border: 2px solid black !important;
          }
        }

        button:last-child {
          margin-top: 2rem;
        }
      }

      .content-bottom {
        padding: 10px;

        .btns {
          button {
            width: 210px;
          }
        }

        //.active {
        //  background: $btn-color-highlight;
        //  color: #ffffff;
        //}
      }
    }
  }
}
</style>
<!--时间选择器-->
<style lang="scss">
.dateBtn {
  color: #1e88e5;
  cursor: pointer;
  padding: 6px 35px;
  border-radius: 4px;

  &:hover {
    background: #edf6fd;
    opacity: 0.8;
  }
}

.vue__time-picker input.display-time {
  height: 3.2em;
  color: #666;
  font-size: 14px;
  border: none;
  border-bottom: 1px solid #949494;
}

.vue__time-picker .controls .char {
  font-size: 29px;
  color: #757575;
}
</style>
