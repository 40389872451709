<template>
  <div class="bookingList" :style="{ width: width, overFlow: 'hidden' }">
    <!--desk list-->
    <div
      v-if="showDesk"
      :tabindex="listIndex"
      role="bookingList"
      :aria-label="data.buildingName + data.floorName + data.seat_name"
    >
      <div class="title x-title" style="position: relative">
        <div class="title-content">
          <img src="@/assets/images/map/meet_calendar_desk@2x.png" alt="" />
          <p>
            {{ data.buildingName }}, {{ data.floorName }},
            {{ data.seat_name }}
          </p>
        </div>
      </div>
      <div class="content" :style="{ minHeight: height, width: width }">
        <!-- <img :src="data.roomImage" style="width: 100%; height: 300px; object-fit: cover" alt="" /> -->
        <newImg :fileUrl="data.roomImage" style="width: 100%; height: 300px; object-fit: cover"></newImg>
        <div class="date">
          <section v-if="data.actualUserName">Booking For: {{ data.actualUserName }}</section>
          <section id="id1">
            {{ startTime }} To {{ endTime }}
            <!--{{ month }}&nbsp;{{ date }},-->
            <!--{{ start }} to {{ end }}-->
            <!--{{ data.start }}&nbsp;to&nbsp;{{ data.end }}-->
          </section>
          <section id="id2">Campus: {{ data.campusName }}</section>
          <section id="id3">Building: {{ data.buildingName }}, {{ data.floorName }}</section>
          <section id="id4">
            <span v-if="client == 'WarnerMedia'"
              >Desk/Office: {{ data.seat_name }} {{ data.floorName }}</span
            >
            <span v-else>Desk: {{ data.seat_name }} {{ data.floorName }}</span>
          </section>
        </div>
        <hr />
        <!--        <div class="checkIn" v-if="showCheckIn">-->
        <!--          <hr>-->
        <!--          <p v-if="data.checkIn" style="margin: 10px" :tabindex="checkIndex" class="alreadyChecked" aria-label="Already checked in">Already checked in</p>-->
        <!--          <v-btn class="x-btn" rounded v-else @click="clickCheckIn('desk')" :tabindex="checkIndex">Check in</v-btn>-->
        <!--        </div>-->
        <hr />
        <div class="btn-group">
          <v-btn
            v-show="showLoaction && currentCampusName == data.campusName"
            rounded
            class="x-btn"
            aria-describedby="id1 id2 id3 id4"
            @click="clickLocation"
            :tabindex="deskLocationIndex"
            @keyup.enter.native="clickLocation"
          >
            <span v-if="client == 'WarnerMedia'">Map Location</span>
            <span v-else>Desk Location</span>
            <!--<span style="opacity: 0;width: 0;">-->
            <!--{{ currentCampusName }}-->
            <!--</span>-->
            <!--<span style="opacity: 0;width: 0;">-->
            <!--{{ data.seat_name }}-->
            <!--</span>-->
          </v-btn>
          <v-btn
            rounded
            :tabindex="deskCancelIndex"
            aria-describedby="id1 id2 id3 id4"
            class="x-btn"
            @click="cancelDesk('desk')"
          >
            <span v-if="client == 'WarnerMedia' || client == 'PinterestCampus'"
              >Cancel Reservation</span
            >
            <span v-else>Cancel Desk</span>
            <!--<span style="opacity: 0;width: 0;">-->
            <!--{{ currentCampusName }}-->
            <!--</span>-->
            <!--<span style="opacity: 0;width: 0;">-->
            <!--{{ data.seat_name }}-->
            <!--</span>-->
          </v-btn>
        </div>
        <hr />
      </div>
    </div>
    <!--room list-->
    <div v-else role="bookingList">
      <div class="title x-title">
        <div
          class="title-content"
          v-if="data.rooms.length > 0"
          style="display: flex; justify-content: space-between"
          :style="{ background: color }"
        >
          <img src="@/assets/images/map/meet_calendar_room@2x.png" alt="" />
          <p>
            {{ data.rooms.length > 0 ? data.rooms[0].resourceName : "" }}
          </p>
        </div>
        <span v-else style="background: #666666; display: block">
          No Room Assignment
        </span>
      </div>
      <div class="content" :style="{ minHeight: height, width: width }">
        <img
          <!-- v-show="data.rooms.length > 0"
          :src="data.rooms.length > 0 ? data.rooms[0].roomImage : ''"
          style="width: 100%; height: 300px; object-fit: cover"
          alt=""
          object-fit="cover"
        /> -->
        <newImg 
          v-show="data.rooms.length > 0" 
          :fileUrl="data.rooms.length > 0 ? data.rooms[0].roomImage : ''" 
          style="width: 100%; height: 300px; object-fit: cover" 
          object-fit="cover">
        </newImg>
        <div class="date">
          <section style="font-size: 18px; font-weight: bold">
            {{ data.summary }}
          </section>
          <section>
            {{ startTimeRoom }} To {{ endTimeRoom }}
            <!--{{ month }}&nbsp;{{ date }},-->
            <!--{{ startRoom }}&nbsp;to&nbsp;{{ endRoom }}-->
          </section>
          <section>Campus: {{ data.rooms.length > 0 ? data.rooms[0].campusName : "" }}</section>
          <section v-show="data.rooms.length > 0">
            Building: {{ data.rooms.length > 0 ? data.rooms[0].buildingName : "" }},
            {{ data.rooms.length > 0 ? data.rooms[0].floorName : "" }}
          </section>
          <section v-show="data.rooms.length > 0">
            Room: {{ data.rooms.length > 0 ? data.rooms[0].resourceName : "" }}
          </section>
        </div>
        <hr />
        <!--        <div class="checkIn" v-if="client=='NatWest'&&showCheckIn">-->
        <!--          <p v-if="data.rooms[0].checkIn" style="margin: 18px">Already checked in</p>-->
        <!--          <v-btn class="x-btn" rounded v-else @click="clickCheckIn('room')">Check in</v-btn>-->
        <!--          <hr>-->
        <!--        </div>-->
        <div class="btn-group">
          <v-btn v-show="showLoaction" rounded class="x-btn" @click="clickLocation">
            Meeting Details
            <span style="opacity: 0; width: 0">
              {{ currentCampusName }}
            </span>
            <span style="opacity: 0; width: 0" v-if="data.rooms.length > 0">
              {{ data.rooms[0] ? data.rooms[0].resourceName : "" }}
            </span>
          </v-btn>
          <v-btn
            v-show="!showLoaction && showOwnerBtn"
            rounded
            outlined
            color="#666666"
            @click="cancelDesk('meeting')"
          >
            Cancel Meeting
          </v-btn>
          <v-btn
            v-show="!showLoaction && showOwnerBtn > 0"
            rounded
            outlined
            color="#666666"
            @click="editMeeting"
            >Edit Meeting
          </v-btn>
          <!--只有在预订的campus才能setting 暂时弃用-->
          <!--<v-btn v-show="(!showLoaction)&&showOwnerBtn>0&&(currentCampusName==data.rooms[0].campusName)" rounded-->
          <!--       outlined color="#666666" @click="editMeeting">Edit-->
          <!--  Meeting-->
          <!--</v-btn>-->
          <!--<v-btn rounded color="#EE2C54" @click="cancelDesk">Cancel Desk</v-btn>-->
        </div>
        <hr />
        <!--与会人员列表-->
        <div style="padding: 1rem" v-show="showParticipants && client != 'NatWest'">
          <p style="font-size: 18px; font-weight: bold">Participants</p>
          <div class="staff-container">
            <staff v-for="(item, index) in data.attendees" :data="item"></staff>
          </div>
        </div>
        <hr v-show="showParticipants && client != 'NatWest'" />
        <div v-dompurify-html="description" style="padding: 1rem; word-break: break-all"></div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import staff from "@/views/dashboard/componentsHa/staff";
import newImg from "@/views/dashboard/componentsHa/img";

export default {
  name: "bookingList",
  props: {
    data: {
      type: Object
    },
    width: {
      default: "100%"
    },
    maxHeight: {
      type: String,
      default: "780px"
    },
    height: {
      default: "42rem"
    },
    showLoaction: {
      default: true
    },
    showParticipants: {
      default: false
    },
    listIndex: {
      default: 1
    },
    checkIndex: {
      default: 2
    },
    deskLocationIndex: {
      default: 3
    },
    deskCancelIndex: {
      default: 4
    },
    showCheckIn: {
      type: Boolean,
      default: false
    }
  },
  components: {
    staff,
    newImg
  },
  watch: {
    data: {
      deep: true,
      handler(newVal, oldVal) {
        console.log("bookingList data change", newVal);
        this.formatData();
      }
    }
  },
  data() {
    return {
      client: "",
      currentCampusName: "", //当前用户所查看的campusName
      attendees: [],
      showOwnerBtn: true,
      showDesk: true,
      description: "",
      dateHash: {
        "01": "Jun",
        "02": "Feb",
        "03": "Mar",
        "04": "Apr",
        "05": "May",
        "06": "Jun",
        "07": "Jul",
        "08": "Aug",
        "09": "Sept",
        10: "Oct",
        11: "Nov",
        12: "Dec"
      },
      month: "",
      date: "",
      start: "",
      end: "",
      startRoom: "",
      endRoom: "",

      startTime: "", //预定的开始时间（desk）
      endTime: "", //预定的结束时间（desk）
      startTimeRoom: "", //预定的开始时间（room）
      endTimeRoom: "", //预定的结束时间（room）
      color: ""
    };
  },
  methods: {
    clickLocation() {
      this.$emit("clickLocation", true);
    },
    cancelDesk(type) {
      this.data.type = type;
      this.$emit("cancelDesk", this.data);
    },
    // 编辑meeting信息
    editMeeting() {
      this.$emit("editMeeting", true);
      // if (window.gtag) {
      //   window.gtag("event", "Booking", {
      //     event_category: "Edit Meeting Click",
      //     event_label: "Edit Meeting",
      //     send_to: localStorage.getItem("googleAnaId") || "",
      //     dimension1: localStorage.getItem("campusName") || ""
      //   });
      // }
      this.$gtag.event('Booking', {
        event_category: 'Edit Meeting Click',
        event_label: 'Edit Meeting',
        send_to: localStorage.getItem("googleAnaId") || "",
        dimension1: localStorage.getItem("campusName") || "",
      });
    },
    // formatData() {
    //   if (this.data.hasOwnProperty('seat_id')) {
    //     this.showDesk = true;
    //     console.log('deskData====================:', this.data);
    //     let start = this.data.start;
    //     let end = this.data.end;
    //     let utcDate = moment.utc(start);
    //     let localDate = moment(utcDate);
    //     this.startTime = localDate.format("MMM D, h:mm a");
    //
    //     let utcDateEnd = moment.utc(end);
    //     let localDateEnd = moment(utcDateEnd);
    //     this.endTime = localDateEnd.format("MMM D, h:mm a");
    //   } else {
    //     this.showDesk = false;
    //     var reg = /(http:\/\/|https:\/\/)((\w|=|\?|\.|\/|&|-)+)/g;
    //     // var numReg = /(\d{6,11})/g;
    //     let str = this.data.description.replaceAll('\n', '<br/>');
    //     // str = str.replace(numReg, "<a href='tel:$1$2'>$1$2</a>");
    //     str = str.replace(reg, "<a href='$1$2' target='_blank'>$1$2</a>");
    //
    //     this.description = str;
    //     let start = this.data.startTime;
    //     let end = this.data.endTime;
    //     let utcDate = moment.utc(start);
    //     let localDate = moment(utcDate);
    //     this.startTimeRoom = localDate.format("MMM D, h:mm a");
    //
    //     let utcDateEnd = moment.utc(end);
    //     let localDateEnd = moment(utcDateEnd);
    //     this.endTimeRoom = localDateEnd.format("MMM D, h:mm a");
    //   }
    // },
    formatData() {
      let timezone = localStorage.getItem("timezone");
      console.log("this.data~~~~~~~~~~~~~~~", this.data);
      if (this.data.hasOwnProperty("seat_id") || this.data.hasOwnProperty("reservation_id")) {
        console.log("SHOW DESK");
        this.showDesk = true;
        // let start = this.data.start;
        // let end = this.data.end;
        let start = moment(this.data.itemStartUnix, "X");
        let end = moment(this.data.itemEndUnix, "X");
        console.log("start1", start);
        console.log("end1", end);
        let utcDate = moment.utc(start);
        let localDate = moment(utcDate);
        let localDateUnix = moment(utcDate).unix();

        console.log("utcDate:", utcDate);
        console.log("localDate:", localDate);
        console.log("localDateUnix:", localDateUnix);

        // this.startTime = localDate.format("MMM D, h:mm a");
        let utcDateEnd = moment.utc(end);
        let localDateEnd = moment(utcDateEnd);
        let localDateEndUnix = moment(localDateEnd).unix();
        // this.endTime = localDateEnd.format("MMM D, h:mm a");
        if (timezone) {
          console.log("Has timezone");
          this.startTime = moment.tz(start, "X", timezone).format("MMM D, h:mm a");
          this.endTime = moment.tz(end, "X", timezone).format("MMM D, h:mm a");
        } else {
          console.log("No timezone");
          this.startTime = moment(localDateUnix, "X").format("MMM D, h:mm a");
          this.endTime = moment(localDateEndUnix, "X").format("MMM D, h:mm a");
          // this.startTime = localDate.format("MMM D, h:mm a");
          // this.endTime = localDateEnd.format("MMM D, h:mm a");
        }
        console.log("this.startTime:", this.startTime);
        console.log("this.endTime:", this.endTime);
        // if(timezone){
        //   this.startTime = moment("")
        // }
      } else {
        console.log("SHOW ROOM");
        this.showDesk = false;
        var reg = /(http:\/\/|https:\/\/)((\w|=|\?|\.|\/|&|-)+)/g;
        // var numReg = /(\d{6,11})/g;
        let str;
        if (this.data.description) {
          str = this.data.description.replaceAll("\n", "<br/>");
          str = str.replace(reg, "<a href='$1$2' target='_blank'>$1$2</a>");
          // if (this.client == "Metlife") {
          //   str = str.replace(/width:0in/g, "width:100%");
          // }
        }
        // str = str.replace(numReg, "<a href='tel:$1$2'>$1$2</a>");
        this.description = str;
        // let start = this.data.start;
        // let end = this.data.end;
        console.log("=====this.data!!!!!!!!!!:", this.data);
        let start = moment(this.data.itemStartUnix, "X");
        let end = moment(this.data.itemEndUnix, "X");
        let utcDate = moment.utc(start);
        let localDate = moment(utcDate);
        let localDateUnix = moment(localDate).unix();
        // this.startTimeRoom = localDate.format("MMM D, h:mm a");
        let utcDateEnd = moment.utc(end);
        let localDateEnd = moment(utcDateEnd);
        let localDateEndUnix = moment(localDateEnd).unix();
        // this.endTimeRoom = localDateEnd.format("MMM D, h:mm a");
        if (timezone) {
          this.startTimeRoom = moment.tz(start, "X", timezone).format("MMM D, h:mm a");
          this.endTimeRoom = moment.tz(end, "X", timezone).format("MMM D, h:mm a");
        } else {
          this.startTimeRoom = moment(localDateUnix, "X").format("MMM D, h:mm a");
          this.endTimeRoom = moment(localDateEndUnix, "X").format("MMM D, h:mm a");
          // this.startTimeRoom = localDate.format("MMM D, h:mm a");
          // this.endTimeRoom = localDateEnd.format("MMM D, h:mm a");
        }
      }
    },
    clickCheckIn(type) {
      let data = this.data;
      data.type = type;
      this.$emit("checkIn", data);
    }
  },
  created() {
    this.client = localStorage.getItem("client");
    //当前用户所查看的campusName
    this.currentCampusName = localStorage.getItem("campusName");
    console.log("bookingList-data", this.data);
    // console.log("roomsLength:",this.data.rooms.length)
    // console.log("organizer:", this.data.organizer)

    // if (localStorage.getItem('client') == 'WarnerMedia') {
    //   this.color = '#e5bc73'
    // } else {
    //   this.color = '#2D7D74';
    // }
    if (this.data.organizer) {
      this.showOwnerBtn =
        this.data.organizer.email == localStorage.getItem("userEmail") ? true : false;
      // console.log("showOwnerBtn:", this.showOwnerBtn);
    }
    this.formatData();
  }
};
</script>

<style scoped lang="scss">
.bookingList {
  hr {
    height: 1px;
    background-color: #cfcfcf;
    border: none;
  }

  .title {
    //background: #2D7D74;
    color: #fff;
    text-align: center;
    //width: 25rem;
    height: 3rem;
    line-height: 3rem;

    .title-content {
      display: flex;
      justify-content: flex-start;

      img {
        //width: 25px;
        //height: 25px;
        //margin: 7px;
        width: 25px;
        height: 20px;
        margin: 10px;
      }

      p {
        width: calc(100% - 25px);
        text-align: center;
        margin-bottom: 16px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-right: 35px; //img的25px+7px
      }

      span {
        display: block;
        width: 100%;
      }
    }
  }

  .content {
    box-sizing: border-box;
    //min-height: 42rem;
    width: calc(25rem + 1px);
    background: #fff;
    border: 1px solid #cfcfcf;
    border-top: none;
    overflow: hidden;

    .date {
      padding: 1rem;

      section:first-child {
        margin-bottom: 1rem;
      }
    }

    .checkIn {
      text-align: center;
      button {
        margin: 10px !important;
      }
    }

    .btn-group {
      margin: 1rem 0;
      display: flex;
      justify-content: space-around;

      button {
        margin: 0 !important;
      }
    }
  }

  .staff-container {
    //height:130px;
    //overflow-y: scroll;
    width: calc(100% + 26px);
  }
}
</style>
